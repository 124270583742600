<template>
    <nav class="navbar has-shadow" role="navigation" aria-label="main navigation">
        <div class="navbar-brand">
            <a class="navbar-item" href="/">
                <img src="/miracl.svg" height="28">
            </a>

            <a role="button" class="navbar-burger" v-bind:class="{'is-active': isActive}" aria-label="menu" aria-expanded="false" data-target="nav-main" v-on:click="toggleMenu">
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
            </a>
        </div>

        <div id="nav-main" class="navbar-menu" v-bind:class="{'is-active': isActive}" v-if="$route.name != 'front'">
            <div class="navbar-start">
                <router-link :to="{ name: 'accounts' }" class="navbar-item">Accounts</router-link>
                <router-link :to="{ name: 'invitations' }" class="navbar-item">Invitations</router-link>
                <router-link :to="{ name: 'audit' }" class="navbar-item">Audit</router-link>
            </div>
            <div class="navbar-end">
                <div class="navbar-item">
                    <router-link :to="{ name: 'logout' }" class="button is-default">Log Out</router-link>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
export default {
    data() {
        return {
            isActive: false
        }
    },
    methods: {
        toggleMenu() {
            this.isActive = !this.isActive
        }
    }
}
</script>