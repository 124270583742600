import Pagination from '@/components/Pagination.vue'

export default {
    components: {
        pagination: Pagination
    },
    methods: {
        getPagingParams() {
            return {
                limit: this.paging.perPage,
                offset: this.paging.page * this.paging.perPage
            }
        },
        handlePaging(page) {
            this.paging.page = page
            this.load()
        }
    }
}
