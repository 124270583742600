import { createApp } from 'vue'
import { createStore } from 'vuex'
import { createRouter, createWebHistory } from 'vue-router'
import App from './App.vue'
import routes from './routes.js'
import Axios from 'axios'

const app = createApp(App)

// Create a new store instance.
const store = createStore({
    state: {
        sessionId: sessionStorage.getItem("sid")
    },
    mutations: {
        setSessionId(state, sessionId){
            sessionStorage.setItem("sid", sessionId)
            state.sessionId = sessionId
        },
        clearSession(state){
            sessionStorage.clear()
            state.sessionId = null
        }
    }
})

app.use(store)

// Router
const router = createRouter({
    history: createWebHistory(),
    routes: routes,
    linkExactActiveClass: 'on'
})

app.use(router)

// HTTP client
const HTTPClient = Axios.create()

app.config.globalProperties.$http = HTTPClient

HTTPClient.interceptors.response.use(
    response => response,
    error => {
        if (error.response.status === 401) {
            store.commit('clearSession')
            router.push({ name: 'front' })
        }

        if (error.response.status === 403) {
            store.commit('clearSession')
            router.replace({ name: 'forbidden' })
        }

        throw error
    }
)

// Get the configuration and then mount the application.
HTTPClient.get('/config.json').then(res => {
    const config = res.data

    HTTPClient.defaults.baseURL = config.apiURL
    app.config.globalProperties.$config = config

    app.mount('#app')
})
