import Front from './pages/Front.vue'
import Login from './pages/Login.vue'
import Logout from './pages/Logout.vue'
import Invitations from './pages/Invitations.vue'
import Accounts from './pages/Accounts.vue'
import Devices from './pages/Devices.vue'
import Audit from './pages/Audit.vue'
import Forbidden from './pages/Forbidden.vue'

export default [
    {
        path: '/',
        component: Front,
        name: 'front'
    },
    {
        path: '/login',
        component: Login,
        name: 'login'
    },
    {
        path: '/logout',
        component: Logout,
        name: 'logout'
    },
    {
        path: '/invitations',
        component: Invitations,
        name: 'invitations'
    },
    {
        path: '/accounts',
        component: Accounts,
        name: 'accounts'
    },
    {
        path: '/devices',
        component: Devices,
        name: 'devices'
    },
    {
        path: '/audit',
        component: Audit,
        name: 'audit'
    },
    {
        path: '/forbidden',
        component: Forbidden,
        name: 'forbidden'
    },
]
