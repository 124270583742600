import Sorter from '@/components/Sorter.vue'

export default {
    components: {
       sorter: Sorter
    },
    methods: {
        getSortingParams() {
            return {
                sort: this.sort.by,
                direction: this.sort.direction
            }
        },
        handleSorting(field) {
            if (this.sort.by === field) {
                this.sort.direction = this.sort.direction === "asc" ? "desc" : "asc"
            } else {
                this.sort.by = field
                this.sort.direction = "asc"
            }

            this.load()
        }
    }
}
