<template>
    <span></span>
</template>

<script type="text/javascript">
export default {
    mounted() {
        this.$store.commit('clearSession')
        this.$router.push({ name: 'front' })
    }
}
</script>
