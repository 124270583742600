<template>
    <div :class="'modal ' + (active ? 'is-active' : '')">
        <div class="modal-background" v-on:click="hide"></div>
        <div class="modal-content">
            <div class="box">
                <slot></slot>
            </div>
        </div>
        <button class="modal-close is-large" aria-label="close" v-on:click="hide"></button>
    </div>
</template>

<script>
export default {
    data() {
        return {
            active: false
        }
    },
    emits: [
        "hid-modal"
    ],
    methods: {
        show() {
            this.active = true
        },
        hide() {
            this.active = false
            this.$emit("hid-modal")
        },
        isActive() {
            return this.active === true
        }
    }
}
</script>
