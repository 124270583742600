<template>
    <img :src="image.src" :width="image.width" :height="image.height" :alt="image.alt">
</template>

<script>
import qrcode from 'qrcode-generator'

export default {
    props: ['link'],
    computed: {
        image(){
            if (!this.link) {
                return ""
            }

            // Generate the QR image
            const qrErrorCorrectionLevel = "H"
            const qrCode = qrcode(this.getQrTypeNumber(this.link), qrErrorCorrectionLevel)
            qrCode.addData(this.link)
            qrCode.make()

            // Get html image tag as string
            const tag = qrCode.createImgTag(Math.floor(280 / qrCode.getModuleCount()), 0)

            // Parse the img
            const parser = new DOMParser()
            const htmlDoc = parser.parseFromString(tag, 'text/html')
            const img = htmlDoc.getElementsByTagName('img')[0]

            return {
                src: img.src,
                width: img.width,
                height: img.height,
                alt: this.link
            }
        },
    },
    methods: {
        /**
         * Calculate the QR code type based on string
         * length for high error correction level
        */
        getQrTypeNumber(text) {
            let type = 1

            // Limits of string length for each type
            const QrCodeLimit = [
                7, 14, 24, 34, 44, 58, 64, 84, 98, 119,
                137, 155, 177, 194, 220, 250, 280, 310, 338, 382,
                403, 439, 461, 511, 535, 593, 625, 658, 698, 742,
                790, 842, 898, 958, 983, 1051, 1093, 1139, 1219, 1273
            ]

            for (let i = 0; i <= QrCodeLimit.length; i++) {
                if (text.length <= QrCodeLimit[i]) {
                    break
                } else {
                    type++
                }
            }

            if (type > QrCodeLimit.length) {
                throw new Error("The provided QR URL is too long to be processed")
            }

            return type
        }
    }
}
</script>
