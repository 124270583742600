const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

function _date(date) {
    return date.getDate() + ' ' + monthNames[date.getMonth()] + ' ' + date.getFullYear()
}

function _pad(num) {
    if (num < 10) {
        return '0' + num
    }

    return num
}

function _time(date) {
    return _pad(date.getHours()) + ':' + _pad(date.getMinutes()) + ':' + _pad(date.getSeconds())
}

export default {
    methods: {
        formatDate: function (value) {
            if (!value) return ''

            return _date(new Date(value))
        },
        formatDateTime: function (value) {
            if (!value) return ''

            const date = new Date(value)

            return _date(date) + ' ' + _time(date)
        }
    }
}
