<template>
    <div class="level">
        <div class="level-left">
            <div class="level-item">
                <h1 class="title">Audit</h1>
            </div>
        </div>
    </div>

    <search :filter="filter" v-on:change="handleFiltering" defaultField="user_id"></search>

    <table class="table is-fullwidth is-bordered">
        <tr>
            <th><sorter field="createdAt" :sort="sort" v-on:change="handleSorting">Date/Time (UTC)</sorter></th>
            <th><sorter field="appName" :sort="sort" v-on:change="handleSorting">App</sorter></th>
            <th><sorter field="userID" :sort="sort" v-on:change="handleSorting">Account</sorter></th>
            <th><sorter field="deviceName" :sort="sort" v-on:change="handleSorting">Device Name</sorter></th>
            <th><sorter field="eventStatus" :sort="sort" v-on:change="handleSorting">Result</sorter></th>
            <th class="has-text-right">Actions</th>
        </tr>
        <tr v-for="(row, key) in list" :key="key">
            <td>{{ formatDateTime(row.createdAt) }}</td>
            <td>{{ row.appName }}</td>
            <td>{{ row.userID }}</td>
            <td>{{ row.deviceName }}</td>
            <td>{{ formatStatus(row.eventStatus) }}</td>
            <td class="has-text-right">
                <a v-on:click.prevent="showDetails(row)" class="btn">Details</a>
            </td>
        </tr>
    </table>

    <pagination :paging="paging" v-on:change="handlePaging"></pagination>

    <modal ref="auditDetailsModal">
        <h4 class="title is-4">Audit Details</h4>
        <pre class="audit-details">{{ JSON.stringify(state.currentRow, null, 4) }}</pre>
    </modal>
</template>

<script>
import Modal from '@/components/Modal.vue'
import PaginationMixin from '@/mixins/Pagination.js'
import DateTimeMixin from '@/mixins/DateTime.js'
import SortingMixin from '@/mixins/Sorting.js'
import FilteringMixin from '@/mixins/Filtering.js'
import { mapState } from 'vuex'

export default {
    components: {
        modal: Modal,
    },
    mixins: [
        PaginationMixin,
        DateTimeMixin,
        SortingMixin,
        FilteringMixin,
    ],
    data() {
        const currentDate = new Date()
        const year = currentDate.getFullYear()
        const month = (currentDate.getMonth() + 1).toString().padStart(2, 0)
        const endDate = new Date(year, currentDate.getMonth() + 1, 0).getDate()

        const startOfMonth = [year, month, '01'].join('-')
        const endOfMonth = [year, month, endDate].join('-')

        return {
            list: [],
            state: {
                currentRow: null
            },
            filter: {
                created_at: "",
                app_name: "",
                user_id: "",
                device_name: "",
                event_status: "",
                from_date: startOfMonth,
                to_date: endOfMonth,
            },
            sort: {
                direction: "desc",
                by: "createdAt"
            },
            paging: {
                total: 0,
                perPage: 10,
                page: 0
            },
        }
    },
    mounted() {
        this.load()
    },
    computed: mapState([
        'sessionId'
    ]),
    methods: {
        load() {
            this.$http.get("/audit", {
                headers: {
                    "X-API-KEY": this.sessionId
                },
                params: {
                    ...this.getFilterParams(),
                    ...this.getSortingParams(),
                    ...this.getPagingParams(),
                }
            }).then(res => {
                this.paging.total = res.data.total
                this.list = res.data.list
            })
        },
        showDetails: function (row) {
            this.state.currentRow = row
            this.$refs.auditDetailsModal.show()
        },
        formatStatus: function (value) {
            if (!value) return ''

            return value.replace('_', ' ').replace(/\b\w/g, l => l.toUpperCase())
        }
    }
}
</script>

<style type="text/css">
.audit-details {
    word-wrap: break-word;
    white-space: pre-wrap;
}
</style>
