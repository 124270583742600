<template>
    <modal v-on:hid-modal="hide" ref="invitationModal">
        <div class="tabs is-toggle is-fullwidth">
            <ul v-if="$config.enableEmailInvitations">
                <li :class="{'is-active': type === 'link'}" v-on:click="changeType('link')"><a>Link</a></li>
                <li :class="{'is-active': type === 'email'}" v-on:click="changeType('email')"><a>Email</a></li>
            </ul>
        </div>
        <div class="field">
            <label class="label" for="userid-field">User ID</label>
            <div class="control">
                <input type="text" class="input" v-model="userId" id="userid-field">
            </div>
        </div>
        <div class="field" v-if="type === 'email'">
            <label class="label" for="email-field">Email Address</label>
            <div class="control">
                <input type="text" class="input" v-model="email" id="email-field">
            </div>
        </div>
        <div class="buttons is-right mt-5">
            <button v-on:click="this.$refs.invitationModal.hide()" class="button">Cancel</button>
            <button v-if="type === 'email'" v-on:click="inviteEmail" class="button is-link" :disabled="!isFormValid">Send Invitation Email</button>
            <button v-if="type === 'link'" v-on:click="inviteLink" class="button is-link" :disabled="!isFormValid">Generate Invitation Link</button>
        </div>
    </modal>

    <modal v-on:hid-modal="hide" ref="invitationLinkModal">
        <div class="columns is-centered m-4">
            <div class="column">
                <h6 class="title is-6">A new device can be registered by scanning the QR or opening the link</h6>
            </div>
        </div>
        <div class="columns is-centered mt-3">
            <div class="column is-half">
                <qrcode ref="qr" v-bind:link="invitationLink"></qrcode>
            </div>
        </div>
        <div class="columns is-cetered">
            <p class="column breakAll">{{ invitationLink }}</p>
        </div>
        <div class="columns mt-5">
            <div class="column">
                <div class="buttons is-right">
                    <button v-on:click="this.$refs.invitationLinkModal.hide()" class="button">Close</button>
                    <button v-on:click="copyLink" class="button is-link">Copy Link</button>
                    <button v-on:click="printLink" class="button is-link">Print</button>
                </div>
            </div>
        </div>
    </modal>

    <modal v-on:hid-modal="hide" ref="invitationEmailConfirmModal">
        <p>Invitation email was sent</p>
        <div class="buttons is-right mt-5">
            <button v-on:click="this.$refs.invitationEmailConfirmModal.hide()" class="button">Close</button>
        </div>
    </modal>

    <div id="print-view" class="print-view">
        <img src="/miracl-black.png" width="192">
        <div class="mt-35">
            <p>A new device with user ID <strong>{{ userIdPrintView }}</strong> can be registered by scanning the QR or opening the link</p>
        </div>
        <div class="mt-35">
            <qrcode ref="qr" :link="invitationLink"></qrcode>
        </div>
        <div class="mt-35">
            <p>{{ invitationLink }}</p>
        </div>
        <div class="mt-35">
            <p>The Link and QR will expire on {{ formatDateTime(linkExpirationPrintView*1000) }} and can be used only once</p>
        </div>
    </div>
</template>

<script>
import QRcode from '@/components/QRcode.vue'
import Modal from '@/components/Modal.vue'
import PrintMixin from '@/mixins/Print.js'
import DateTimeMixin from '@/mixins/DateTime.js'
import { mapState } from 'vuex'
import { watch } from '@vue/runtime-core'
import { ref } from "vue";

export default {
    components: {
        modal: Modal,
        qrcode: QRcode
    },
    mixins: [
        PrintMixin,
        DateTimeMixin
    ],
    emits: [
        "created-invitation"
    ],
    setup() {
        var type = ref("link")
        var userId = ref("")
        var email = ref("")
        var invitationLink = ref("")
        var userIdPrintView = ref("")
        var linkExpirationPrintView = ref("")
        var isFormValid = ref(false)

        watch([userId, email, type], ([userId, email, type]) => {
            switch (type) {
                case "link": {
                    if (userId) {
                        isFormValid.value = true
                    } else {
                        isFormValid.value = false
                    }

                    break
                }
                case "email": {
                    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

                    if (userId && re.test(String(email).toLowerCase())) {
                        isFormValid.value = true
                    } else {
                        isFormValid.value = false
                    }

                    break
                }
                default:
                    break
            }
        })

        return {
            userId,
            type,
            email,
            invitationLink,
            userIdPrintView,
            linkExpirationPrintView,
            isFormValid
        }
    },
    mounted() {
        document.addEventListener("keydown", e => {
            // metaKey is for mac keyboard (command btn)
            if (e.key === "p" && (e.ctrlKey || e.metaKey)) {
                if (this.$refs.invitationLinkModal.isActive()) {
                    e.preventDefault()
                    this.printLink()
                }
            }
        })
    },
    computed: mapState([
        "sessionId"
    ]),
    methods: {
        changeType(type) {
            this.type = type
        },
        inviteLink() {
            this.$http.post("/invitation/link", {
                userId: this.userId
            }, {
                headers: {
                    "X-API-KEY": this.sessionId
                }
            }).then(res => {
                this.userIdPrintView = this.userId
                this.linkExpirationPrintView = res.data.invitation.expiration
                this.invitationLink = res.data.link
                this.$refs.invitationModal.hide()
                this.$refs.invitationLinkModal.show()
                this.$emit("created-invitation")
            })
        },
        inviteEmail() {
            this.$http.post("/invitation/email",{
                userId: this.userId,
                email: this.email.trim(),
            }, {
                headers: {
                    "X-API-KEY": this.sessionId
                }
            }).then(() => {
                this.$refs.invitationModal.hide()
                this.$refs.invitationEmailConfirmModal.show()
                this.$emit("created-invitation")
            })
        },
        setUserId(userId) {
            this.userId = userId
            this.email = userId
        },
        show(userId) {
            if (userId) {
                this.setUserId(userId)
            }

            this.$refs.invitationModal.show()
        },
        hide() {
            this.userId = ""
            this.email = ""
        },
        copyLink() {
            let textArea = document.createElement("textarea")
            let successful

            // Place in top-left corner of screen regardless of scroll position.
            textArea.style.position = "fixed"
            textArea.style.top = 0
            textArea.style.left = 0

            // Ensure it has a small width and height. Setting to 1px / 1em
            // doesn't work as this gives a negative w/h on some browsers.
            textArea.style.width = "2em"
            textArea.style.height = "2em"

            // We don't need padding, reducing the size if it does flash render.
            textArea.style.padding = 0

            // Clean up any borders.
            textArea.style.border = "none"
            textArea.style.outline = "none"
            textArea.style.boxShadow = "none"

            // Avoid flash of white box if rendered for any reason.
            textArea.style.background = "transparent"

            textArea.value = this.invitationLink

            document.body.appendChild(textArea)

            textArea.select()

            try {
                successful = document.execCommand("copy")
            } catch (err) {
                successful = false
            }

            document.body.removeChild(textArea)

            return successful
        },
        printLink() {
            this.print("print-view", ["print.css"])
        }
    }
}
</script>

<style>
    .print-view{
        visibility: hidden;
    }

    .breakAll {
        word-break: break-all;
    }
</style>
