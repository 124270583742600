<template>
    <site-header v-if="sessionId"></site-header>

    <div class="section">
        <div class="container is-max-desktop">
            <router-view v-slot="{ Component }">
                <component :is="Component" />
            </router-view>
        </div>
    </div>
</template>

<script>
import Header from './components/Header.vue'
import { mapState } from 'vuex'

export default {
    name: 'App',
    components: {
        'site-header': Header,
    },
    computed: mapState([
        'sessionId'
    ])
}
</script>
