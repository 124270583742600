<template>
    <div class="field has-addons" :class="state.error ? 'is-danger' : ''">
        <div class="control">
            <div class="button">
                <span v-for="(row, key) in active" :key="key" class="tag"><span>{{ row.label }}</span> <span class="remove-btn" v-on:click="handleRemove(row.field)">✕</span></span>
            </div>
        </div>
        <div class="control is-expanded">
            <div class="dropdown" :class="state.suggestions.length ? 'is-active' : ''">
                <input ref="input" class="input" type="text" :placeholder="placeholder ? placeholder : 'Filter'" v-on:input="handleInput" v-on:change="handleChange">

                <div class="dropdown-menu" role="menu">
                    <div class="dropdown-content">
                        <a href="#" class="dropdown-item" v-for="(suggestion, key) in state.suggestions" :key="key" v-on:click.prevent="insertField(suggestion)">
                            {{ suggestion }}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['placeholder', 'filter', 'defaultField'],
    emits: ['change'],
    data() {
        return {
            state: {
                error: false,
                suggestions: []
            }
        }
    },
    computed: {
        active() {
            let active = []

            for (let key in this.filter) {
                if (this.filter[key]) {
                    active.push({
                        field: key,
                        value: this.filter[key],
                        label: key + ' = ' + this.filter[key],
                    })
                }
            }

            return active
        },
    },
    methods: {
        handleInput(e) {
            this.state.suggestions = this.suggest(e.target.value)
        },
        suggest(value) {
            let res = []

            if (!value.length) {
                return res
            }

            for (let key in this.filter) {
                if (key.toLowerCase().includes(value.replaceAll(' ', '').toLowerCase())) {
                    res.push(key)
                }
            }

            return res
        },
        handleRemove(key) {
            let updated = {}
            updated[key] = ''

            this.$emit('change', updated)
        },
        handleChange(e) {
            const parsed = this.parseInput(e.target.value)

            if (parsed !== false) {
                e.target.value = ''
                this.$emit('change', parsed)
            } else {
                this.flashError()
            }
        },
        parseInput(value) {
            value = value.replaceAll(' ', '').split(',')

            let parsed = {}

            for (let i in value) {
                let filter = value[i].split('=')

                if (filter.length === 2) {
                    let key = this.filterKeyExists(filter[0])

                    if (key) {
                        parsed[key] = filter[1]
                    } else {
                        return false
                    }
                } else if (this.defaultField && value.length === 1 && filter.length === 1) {
                    parsed[this.defaultField] = filter[0]
                } else {
                    return false
                }
            }

            return parsed
        },
        filterKeyExists(value) {
            for (let key in this.filter) {
                if (key.toLowerCase() === value.toLowerCase()) {
                    return key
                }
            }

            return false
        },
        insertField(field) {
            this.state.suggestions = []
            this.$refs.input.value = field + '='
            this.$refs.input.focus()
        },
        flashError() {
            this.state.error = true

            setTimeout(() => {
                this.state.error = false
            }, 500)
        }
    }
}
</script>

<style scoped>
.remove-btn {
    margin-left: 5px;
    font-size: 1.3em;
    display: inline-block;
}

.tag:not(:last-child) {
    margin-right: 3px;
}

.field {
    border-radius: 4px;
}

.dropdown {
    width: 100%;
}

.field .button {
    border-right-width: 0;
    padding-right: 0;
    padding-left: 0.75em;
}

.field .button:empty {
    padding-left: 4px;
}

.field .input {
    border-left-width: 0;
    box-shadow: none;
}

.field:hover .button,
.field:hover .input {
    border-color: #b5b5b5;
}

.field:focus-within .button,
.field:focus-within .input {
    border-color: #3273dc;
}

.field.is-danger .button,
.field.is-danger .input {
    border-color: #f14668;
}

.field:focus-within {
    box-shadow: 0 0 0 0.125em rgb(50 115 220 / 25%);
}

.field.is-danger {
    box-shadow: 0 0 0 0.125em rgb(241 70 104 / 25%);
}
</style>
