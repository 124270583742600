<template>
    <div class="level">
        <div class="level-left">
            <div class="level-item">
                <h1 class="title">Invitations</h1>
            </div>
        </div>
        <div class="level-right">
            <div class="level-item">
                <button v-on:click.prevent="openInvitationModal" class="button is-link">Create a new invite</button>
            </div>
        </div>
    </div>

    <search :filter="filter" v-on:change="handleFiltering" defaultField="user_id" v-if="$config.enableInvitationSearch"></search>

    <table class="table is-bordered is-fullwidth">
        <tr v-if="$config.enableInvitationSearch">
            <th><sorter :sort="sort" field="user_id" v-on:change="handleSorting">User ID</sorter></th>
            <th><sorter :sort="sort" field="expiration" v-on:change="handleSorting">Expiration</sorter></th>
            <th><sorter :sort="sort" field="status" v-on:change="handleSorting">Status</sorter></th>
            <th class="has-text-right">Actions</th>
        </tr>
        <tr v-else>
            <th>User ID</th>
            <th>Expiration</th>
            <th>Status</th>
            <th class="has-text-right">Actions</th>
        </tr>
        <tr v-for="(row, key) in list" :key="key">
            <td>{{ row.userId }}</td>
            <td>{{ formatDateTime(row.expiration*1000) }}</td>
            <td>{{ row.status }}</td>
            <td class="has-text-right">
                <a v-if="row.status == 'Pending'" v-on:click.prevent="cancel(row.code)">Cancel</a>
            </td>
        </tr>
    </table>

    <pagination :paging="paging" v-on:change="handlePaging"></pagination>

    <invitationmodal v-on:created-invitation="load" ref="invitationModal"></invitationmodal>
</template>

<script>
import InvitationModal from '@/components/InvitationModal.vue'
import PaginationMixin from '@/mixins/Pagination.js'
import SortingMixin from '@/mixins/Sorting.js'
import FilteringMixin from '@/mixins/Filtering.js'
import DateTimeMixin from '@/mixins/DateTime.js'
import { mapState } from 'vuex'

export default {
    mixins: [
        PaginationMixin,
        SortingMixin,
        FilteringMixin,
        DateTimeMixin
    ],
    components: {
        invitationmodal: InvitationModal
    },
    data() {
        return {
            list: [],
            state: {
                email: "",
                inviteLink: ""
            },
            filter: {
                user_id: "",
                status: ""
            },
            paging: {
                total: 0,
                perPage: 10,
                page: 0
            },
            sort: {
                direction: "desc",
                by: "expiration"
            }
        }
    },
    mounted() {
        this.load()
    },
    computed: mapState([
        'sessionId'
    ]),
    methods: {
        load() {
            this.$http.get("/invitations", {
                headers: {
                    "X-API-KEY": this.sessionId
                },
                params: {
                    ...this.getFilterParams(),
                    ...this.getSortingParams(),
                    ...this.getPagingParams(),
                }
            }).then(res => {
                this.paging.total = res.data.total
                this.list = res.data.list
            })
        },
        openInvitationModal() {
            this.$refs.invitationModal.show()
        },
        cancel(code) {
            this.$http.patch("/invitation", {
                code: code,
                status: "Cancelled"
            }, {
                headers: {
                    "X-API-KEY": this.sessionId
                }
            }).then(res => {
                this.load(res)
            })
        }
    }
}
</script>
