<template>
    <div class="level">
        <div class="level-left">
            <div class="level-item">
                <h1 class="title">Devices</h1>
            </div>
        </div>
        <div class="level-right">
            <div class="level-item">
                <a v-on:click.prevent="openInvitationModal" class="button is-link">Enroll a new device</a>
            </div>
        </div>
    </div>

    <search :filter="filter" v-on:change="handleFiltering" defaultField="user_id"></search>

    <table class="table is-fullwidth is-bordered">
        <tr>
            <th><sorter field="userId" :sort="sort" v-on:change="handleSorting">User ID</sorter></th>
            <th><sorter field="deviceName" :sort="sort" v-on:change="handleSorting">Device Name</sorter></th>
            <th><sorter field="createdAt" :sort="sort" v-on:change="handleSorting">Created</sorter></th>
            <th><sorter field="revoked" :sort="sort" v-on:change="handleSorting">Status</sorter></th>
            <th class="has-text-right">Actions</th>
        </tr>
        <tr v-for="(row, key) in devices" :key="key">
            <td>{{ row.userId }}</td>
            <td>{{ row.deviceName }}</td>
            <td>{{ row.createdAt }}</td>
            <td>
                {{ row.revoked ? 'Revoked' : 'Active' }}
            </td>
            <td class="has-text-right">
                <a v-if="!row.revoked" v-on:click.prevent="confirmRevocation(row)" class="btn">
                    Revoke
                </a>
            </td>
        </tr>
    </table>

    <pagination :paging="paging" v-on:change="handlePaging"></pagination>

    <invitationmodal ref="invitationModal"></invitationmodal>

    <modal ref="revocationConfirmModal">
        <p>Are you sure you want to revoke this identity?</p>
        <div class="buttons is-right">
            <button v-on:click="$refs.revocationConfirmModal.hide()" class="button is-primary">Cancel</button>
            <button v-on:click="handleRevocation" class="button is-danger">Confirm</button>
        </div>
    </modal>
</template>

<script>
import Modal from '@/components/Modal.vue'
import InvitationModal from '@/components/InvitationModal.vue'
import PaginationMixin from '@/mixins/Pagination.js'
import SortingMixin from '@/mixins/Sorting.js'
import FilteringMixin from '@/mixins/Filtering.js'
import { mapState } from 'vuex'

export default {
    components: {
        modal: Modal,
        invitationmodal: InvitationModal
    },
    mixins: [
        PaginationMixin,
        SortingMixin,
        FilteringMixin
    ],
    data() {
        return {
            devices: [],
            state: {
                invitationLink: "",
                currentDevice: null,
            },
            filter: {
                user_id: this.$route.query.user_id,
                device_name: "",
                revoked: "",
            },
            sort: {
                direction: "desc",
                by: "createdAt"
            },
            paging: {
                total: 0,
                perPage: 10,
                page: 0
            },
        }
    },
    mounted() {
        this.load()
    },
    computed: mapState([
        'sessionId'
    ]),
    methods: {
        load() {
            this.$http.get("/devices", {
                headers: {
                    "X-API-KEY": this.sessionId
                },
                params: {
                    ...this.getFilterParams(),
                    ...this.getSortingParams(),
                    ...this.getPagingParams(),
                }
            }).then(res => {
                this.paging.total = res.data.total
                this.devices = res.data.list
            })
        },
        confirmRevocation(device) {
            this.state.currentDevice = device
            this.$refs.revocationConfirmModal.show()
        },
        handleRevocation() {
            if (!this.state.currentDevice || this.state.currentDevice.revoked) {
                return
            }

            this.$http.post("/revoke", {
                device: this.state.currentDevice
            }, {
                headers: {
                    "X-API-KEY": this.sessionId
                }
            }).then(() => {
                this.state.currentDevice.revoked = true
                this.$refs.revocationConfirmModal.hide()
            })
        },
        openInvitationModal() {
            this.$refs.invitationModal.show(this.filter.user_id)
        }
    }
}
</script>
