function _addStylesheet(win, styles) {
    styles.forEach((style) => {
        const link = win.document.createElement("link")
        link.setAttribute("rel", "stylesheet")
        link.setAttribute("type", "text/css")
        link.setAttribute("href", style)
        win.document.getElementsByTagName("head")[0].appendChild(link)
    })
}

// styles is array with paths to .css files
function _print(elementId, styles) {
    const element = window.document.getElementById(elementId)
    if (!element) {
        alert(`Element to print #${elementId} not found!`)
        return
    }

    styles = styles ? styles : []

    const win = window.open('', '_blank', 'resizable=off,scrollbars=yes')

    win.document.write(`
        <html>
            <head>
                <title>${window.document.title}</title>
                <style type="text/css" media="print">
                @page 
                {
                    size: auto;
                    margin: 0; // removes the header and footer from print layout
                }
                body
                {
                    margin: 15mm;
                }
                </style>
            </head>
            <body>
                ${element.innerHTML}
            </body>
        </html>
    `)

    _addStylesheet(win, styles)

    setTimeout(() => {
        win.document.close()
        win.focus()
        win.print()
        win.close()
    }, 1000)

    return true
}

export default {
    methods: {
        print: _print
    }
}
