<template>
    <span></span>
</template>

<script>
import { mapState } from 'vuex'

export default {
    mounted() {
        if (this.sessionId) {
            this.$router.push({ name: 'accounts' })
            return
        }

        const authorizeURL = this.$config.oidcIssuer + '/authorize?client_id=' + this.$config.oidcClientID + '&redirect_uri=' + encodeURIComponent(this.$config.oidcRedirectURI) + '&response_type=code&scope=openid+email'

        window.location = authorizeURL
    },
    computed: mapState([
        'sessionId'
    ])
}
</script>
