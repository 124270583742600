
<template>
    <section class="hero is-medium">
        <div class="hero-body">
            <figure class="image miracl-logo">
            <img src="/miracl.svg" />
            </figure>
            <p class="title">Forbidden</p>
            <p class="subtitle">You are not authorized to view this page</p>
            <a href="/" class="button">Log in</a>
        </div>
    </section>
</template>

<style>
.miracl-logo {
    width: 128px;
    margin-bottom: 15px;
}
</style>
