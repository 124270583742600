import SearchBar from '@/components/SearchBar.vue'

export default {
    components: {
        search: SearchBar
    },
    methods: {
        getFilterParams() {
            let filters = {}

            for (let val in this.filter) {
                filters['filter_' + val] = this.filter[val]
            }

            return filters
        },
        handleFiltering(values) {
            for (let key in values) {
                this.filter[key] = values[key]
            }

            if (this.paging && this.paging.page) {
                this.paging.page = 0
            }

            this.load()
        }
    }
}
