<template>
    <nav class="pagination" role="navigation" aria-label="pagination" v-if="paging.perPage < paging.total">
        <a class="pagination-previous" v-on:click.prevent="prevPage">Previous</a>
        <a class="pagination-next" v-on:click.prevent="nextPage">Next</a>

        <ul class="pagination-list">
            <li v-for="(page, key) in pagination" :key="key">
                <span v-if="page === '.'" class="pagination-ellipsis">&hellip;</span>
                <a v-else class="pagination-link" :class="{ 'is-current': (paging.page == page) }" v-on:click.prevent="goToPage(page)">{{ page + 1 }}</a>
            </li>
        </ul>
    </nav>
</template>

<script>
export default {
    props: ['paging'],
    computed: {
        pagination() {
            const current = this.paging.page
            const last = Math.ceil(this.paging.total / this.paging.perPage) - 1
            const left = current - 3
            const right = current + 3

            let range = []

            range.push(0)

            let dotting = false

            for (let i = 1; i < last; i++) {
                if (i > left && i < right) {
                    range.push(i)
                    dotting = false
                } else if (!dotting) {
                    range.push('.')
                    dotting = true
                }
            }

            range.push(last)

            return range
        }
    },
    methods: {
        prevPage() {
            if (this.paging.page < 1) {
                return
            }

            this.$emit('change', this.paging.page - 1)
        },
        nextPage() {
            if ((this.paging.page + 1) * this.paging.perPage > this.paging.total) {
                return
            }

            this.$emit('change', this.paging.page + 1)
        },
        goToPage(page) {
            this.$emit('change', page)
        }
    }
}
</script>
