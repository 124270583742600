<template>
    <span></span>
</template>

<script type="text/javascript">
export default {
    mounted() {
        const code = this.$route.query.code

        this.$http.get('/login', {
            params: {
                'code': code,
            }
        }).then(response => {
            this.$store.commit('setSessionId', response.data.sessionId)
            this.$router.push({ name: 'accounts' })
        })
    }
}
</script>