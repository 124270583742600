<template>
    <h1 class="title">Accounts</h1>

    <search :filter="filter" defaultField="user_id" v-on:change="handleFiltering" v-if="$config.enableAccountSearch"></search>

    <table class="table is-bordered is-fullwidth">
        <tr v-if="$config.enableAccountSearch">
            <th><sorter :sort="sort" field="user_id" v-on:change="handleSorting">User ID</sorter></th>
            <th><sorter :sort="sort" field="name" v-on:change="handleSorting">Name</sorter></th>
            <th><sorter :sort="sort" field="last_login" v-on:change="handleSorting">Last Login</sorter></th>
            <th><sorter :sort="sort" field="created_at" v-on:change="handleSorting">Created</sorter></th>
            <th class="has-text-right">Actions</th>
        </tr>
        <tr v-else>
            <th>User ID</th>
            <th>Name</th>
            <th>Last Login</th>
            <th>Created</th>
            <th class="has-text-right">Actions</th>
        </tr>
        <tr v-for="(row, key) in list" :key="key">
            <td><span :title="row.userId">{{ row.userId }}</span></td>
            <td>{{ row.name }}</td>
            <td>{{ row.lastLogin }}</td>
            <td>{{ row.createdAt }}</td>
            <td class="has-text-right">
                <router-link :to="{ name: 'devices', query: { user_id: row.userId } }" class="action-btn">Devices</router-link>
                <a v-on:click="openInvitationModal(row.userId)" class="action-btn">Invite</a>
            </td>
        </tr>
    </table>

    <pagination :paging="paging" v-on:change="handlePaging"></pagination>

    <invitationmodal ref="invitationModal"></invitationmodal>
</template>

<script>
import InvitationModal from '@/components/InvitationModal.vue'
import PaginationMixin from '@/mixins/Pagination.js'
import SortingMixin from '@/mixins/Sorting.js'
import FilteringMixin from '@/mixins/Filtering.js'
import { mapState } from 'vuex'

export default {
    mixins: [
        PaginationMixin,
        SortingMixin,
        FilteringMixin
    ],
    components: {
        invitationmodal: InvitationModal
    },
    data() {
        return {
            list: [],
            currentUserId: null,
            paging: {
                total: 0,
                perPage: 10,
                page: 0
            },
            sort: {
                direction: "desc",
                by: "last_login"
            },
            filter: {
                user_id: ""
            }
        }
    },
    mounted() {
        this.load()
    },
    computed: mapState([
        'sessionId'
    ]),
    methods: {
        load() {
            this.$http.get('/accounts', {
                headers: {
                    "X-API-KEY": this.sessionId
                },
                params: {
                    ...this.getFilterParams(),
                    ...this.getSortingParams(),
                    ...this.getPagingParams(),
                }
            }).then(res => {
                this.paging.total = res.data.total
                this.list = res.data.list
            })
        },
        openInvitationModal(userId) {
            this.$refs.invitationModal.show(userId)
        }
    }
}
</script>

<style>
.action-btn {
    padding-left: 10px;
}
</style>
